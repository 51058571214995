<template>
  <div id="attendance">
    <v-row>
      <v-col>
        <h2 class="mb-0 main-color">Attendance</h2>
        <p class="mb-0">Attendance should be marked before 5pm</p>
      </v-col>
    </v-row>
    <div class="card height-auto v-application v-application--is-ltr">
      <div class="card-body">
        <div>
          <v-row>
            <v-col cols="12" sm="4">
              <v-row>
                <v-col cols="4" sm="4" class="text-right">
                  <label for="Date" class="label">{{ $t("Date") }}:</label>
                </v-col>
                <v-col cols="8" sm="8" id="attendancemenu">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="selectedDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :disabled="edit"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="selectedDate"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <!-- <v-date-picker v-model="selectedDate" no-title scrollable max-date='2022-11-15'> -->
                      <v-date-picker v-model="selectedDate"  no-title scrollable class="mt-4" :max="date">
                      <!-- </v-date-picker> -->
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">{{
                        $t("Cancel")
                      }}</v-btn>
                      <v-btn text color="primary" @click="saveDate">{{
                        $t("OK")
                      }}</v-btn>
                    </v-date-picker>
                  </v-menu>
                  <!-- <v-select
                      :items="dates"
                      v-model="selectedDate"
                      solo
                      @change="getLessons"
                  >
                  </v-select>-->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4">
              <v-row>
                <v-col cols="6" sm="6" class="text-right">
                  <label for="Date" class="label">{{ $t("Class") }}:</label>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-select
                    :items="classes"
                    item-text="name"
                    item-value="id"
                    v-model="selectedClass"
                    solo
                    @change="getLessons"
                    :disabled="edit"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="4">
              <div class="btns text-right px-5">
                <v-btn
                  class="main-color attended"
                  @click="attend"
                  v-if="edit"
                  >{{ $t("Attended") }}</v-btn
                >
                <v-btn class="main-color absent" @click="absent" v-if="edit">{{
                  $t("Absent")
                }}</v-btn>
                <v-icon
                  class="main-color"
                  @click="openEdit()"
                  v-if="!edit"
                  large
                  :title="$t('Edit')"
                  >edit</v-icon
                >

                <!-- ================== Activity logs ================== -->
                <v-btn
                  icon
                  to="/systemlogging/attendance"
                  v-if="activitylogPermission && !edit"
                  target="_blank"
                >
                  <v-icon class="main-color" large :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <table class="table table-attendance" v-if="attendances.length != 0">
          <thead>
            <tr>
              <th v-if="edit == true">
                <v-checkbox
                  v-model="selectedAll"
                  style="margin: 0px; padding: 0px"
                  hide-details
                  @change="selectAllStudents"
                />
              </th>
              <th>{{ $t("Student Name") }}</th>
              <th v-for="(lesson, index) in lessons" :key="index">
                {{ lesson.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in attendances" :key="index">
              <td v-if="edit == true">
                <v-checkbox
                  v-model="selected"
                  :value="item.student_id"
                  class="checkbox"
                  style="margin: 0px; padding: 0px"
                  hide-details
                />
              </td>
              <td>{{ item.student_name }}</td>
              <td
                v-for="(lesson, lessonIndex) in item.lessons"
                :key="lessonIndex"
              >
                <v-icon
                  v-if="lesson['value'] && edit == false"
                  color="#5fb870"
                  :title="$t('Attendant')"
                  >check</v-icon
                >
                <v-icon
                  v-if="!lesson['value'] && edit == false"
                  color="error"
                  :title="$t('Absent')"
                  >close</v-icon
                >
                <v-checkbox
                  v-model="lesson['value']"
                  v-if="edit == true"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="attendances.length == 0">
          <v-row>
            <v-col cols="12" md="12">
              <v-alert class="info_alert">{{
                $t("There is no data available")
              }}</v-alert>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-card-actions v-if="edit">
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="cancel()">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          :loading="loading"
          @click="save()"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";

export default {
  name: "classAttendance",
  data() {
    return {
      date: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
      activitylogPermission: ACL.checkPermission("activitylog"),

      selectedAll: false,
      role: localStorage.selectedRole,
      edit: false,
      singleSelect: false,
      selected: [],
      loading: false,
      headers: [
        {
          text: this.$i18n.t("Code"),
          sortable: false,
          value: "code",
        },
        { text: this.$i18n.t("Name"), value: "name" },
        { text: this.$i18n.t("Gender"), value: "gender" },
        { text: this.$i18n.t("Attendance"), value: "attended" },
      ],
      headersAdmin: [
        {
          text: this.$i18n.t("Student Name"),
          value: "student_name",
        },
      ],
      itemsAdmin: {
        mahmoud4: {
          student_id: 1,
          lessons: [
            {
              attendance_id: null,
              lesson_id: 2,
              name: "2",
              academic_week_id: 13,
              teacher_id: 3,
              value: true,
            },
            {
              attendance_id: null,
              lesson_id: 3,
              name: "3",
              academic_week_id: 13,
              teacher_id: 3,
              value: true,
            },
            {
              attendance_id: null,
              lesson_id: 4,
              name: "1",
              academic_week_id: 13,
              teacher_id: 3,
              value: true,
            },
            {
              attendance_id: null,
              lesson_id: 5,
              name: "4",
              academic_week_id: 13,
              teacher_id: 3,
              value: true,
            },
          ],
        },
      },
      // lessonsAdmin: [
      //   {
      //     text: "Name",
      //     value: "student_name"
      //   },
      //   {
      //     text: "1",
      //     value: lessons[0]["lesson_name"]
      //   },
      //   {
      //     text: "2",
      //     value: lessons[0]["lesson_name"]
      //   }
      // ],
      attendances: [],
      classes: [],
      dates: [],
      lessons: [],
      selectedClass: "",
      selectedDate: "",
      selectedLesson: "",
      menu: false,
      modal: false,
    };
  },
  methods: {
    // allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,
    selectAllStudents() {
      let _this = this;
      if (this.selectedAll) {
        this.attendances.forEach((item) => {
          _this.selected.push(item.student_id);
        });
      } else {
        this.attendances.forEach((item) => {
          _this.selected.splice(_this.selected.indexOf(item.student_id), 1);
        });
      }
    },
    saveDate() {
      this.$refs.menu.save(this.selectedDate);
      this.getAttendances();
      this.getClasses();
      this.getLessons();
    },
    openEdit() {
      this.edit = true;
      console.log(this.edit);
    },

    cancel() {
      this.edit = false;
      this.getAttendances();
    },
    save() {
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/attendance/store/" + this.selectedClass,
          this.attendances,
          // {
          //   class_id: this.selectedClass,
          // },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (!response.data.status.error) {
            this.edit = false;
            this.getAttendances();
          }
        });
      this.loading = false;
    },
    getClasses() {
      axios
        .get(this.getApiUrl + "/attendance/getClassesForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.classes = response.data.data;
          this.getLessons();
        });
    },
    getLessons() {
      axios
        .get(
          this.getApiUrl +
            "/attendance/getLessonsOfClass?class_id=" +
            this.selectedClass +
            "&date=" +
            this.selectedDate,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.lessons = response.data.data;
          this.headersAdmin = [];
          this.getAttendances();
        });
    },
    getDates() {
      axios
        .get(this.getApiUrl + "/attendance/getDatesForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.dates = response.data.data.dates;
          this.selectedDate = response.data.data.default;
        });
    },
    getAttendances() {
      if (this.selectedClass != "" && this.selectedDate != "") {
        axios
          .get(
            this.getApiUrl +
              "/attendance?class_id=" +
              this.selectedClass +
              "&date=" +
              this.selectedDate,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.attendances = response.data.data;
            // let _this = this;
            // let i = 0;
            // this.attendances.forEach((item) => {
            //   console.log(item);
            //   _this.selected[i] = false;
            //   i++;
            // });
          });
      }
    },
    attend() {
      if (this.attendances.length > 0 && this.selected.length == 0) {
        this.attendances.forEach((item) => {
          item.lessons.forEach((lesson) => {
            lesson.value = true;
          });
        });
      } else if (this.selected.length != 0) {
        let _this = this;
        _this.selected.forEach((selectedItem) => {
          _this.attendances.forEach((item) => {
            if (selectedItem == item.student_id) {
              item.lessons.forEach((lesson) => {
                lesson.value = true;
              });
            }
          });
        });
      }
    },
    absent() {
      // console.log(this.selected);
      if (this.attendances.length > 0 && this.selected.length == 0) {
        this.attendances.forEach((item) => {
          item.lessons.forEach((lesson) => {
            lesson.value = false;
          });
        });
      } else if (this.selected.length != 0) {
        let _this = this;
        _this.selected.forEach((selectedItem) => {
          _this.attendances.forEach((item) => {
            // console.log(item);
            if (selectedItem == item.student_id) {
              item.lessons.forEach((lesson) => {
                lesson.value = false;
              });
            }
          });
        });
      }
    },
  },
  mounted() {
    //console.log(new Date().getFullYear() + '-' + (new Date().getMonth() +1) + '-' + new Date().getDate());
    this.getDates();
    this.getClasses();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.label {
  margin-top: 1rem;
}

.class-scroll {
  margin-top: 1rem;
}
.btns {
  margin-top: 1rem;
}

.table-attendance thead tr:last-child th {
  font-size: 13px !important;
  /* font-weight: 700 !important; */
  border-bottom: none !important;
  border-top: none !important;
  padding: 0 5px !important;
  vertical-align: top;
}
.table-attendance thead tr {
  background-color: transparent !important;
  border-radius: 0;
  box-shadow: unset !important;
  border-bottom: none !important;
  border-top: none !important;
  vertical-align: top;
}

.table-attendance tbody tr:nth-of-type(odd) {
  background-color: #eaeefc;
}
.table-attendance tr {
  background-color: #ffffff;
  box-shadow: #d1d1d1 0px 2px 8px 0px !important;
  -webkit-box-shadow: #d1d1d1 0px 2px 8px 0px !important;
  /* filter: drop-shadow(0 3px 0px #D1D1D1); */
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
  /* box-shadow: 0 0px 20px 0 rgba(0,0,0,0.05); */
  border-radius: 9px;
  height: 48px !important;
  border-bottom: none !important;
  border-top: none !important;
  border: none !important;
}
.table-attendance {
  background-color: #ffffff;
  border-spacing: 0px 8px !important;
  border-collapse: separate;
  border-color: transparent;
}
.table-attendance td {
  font-size: 13px !important ;
  max-width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 0 5px;
  vertical-align: middle;
}
/* .table-attendance td:first-child {
  padding-left: 20px;
} */
.table-attendance thead tr th {
  color: rgba(0, 0, 0, 0.6);
  border-bottom: none !important;
  border-top: none !important;
  vertical-align: top !important;
}
</style>
